* {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

header {
  background-color: rgb(70, 71, 117);
  position: sticky;
  padding: 30px 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: -40px;
  margin-bottom: 0px;
}
header > h1 {
  color: white;
  margin: 0;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
}
header > span {
  color: white;
}
hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid rgb(58, 92, 148);
  margin: 20px 0 30px 0;
  padding: 0;
}
.info-container > div > div > p {
  padding: 3px 5px;
  border-radius: 2px;
  background-color: rgb(220, 226, 247);
  display: inline;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  word-spacing: 3px;
}
.info-container > div > h4 {
  margin-bottom: 6px;
}
.info-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.info-container i {
  margin-bottom: 5px;
}
.info-container .icon-label::after {
  content: "\a";
  white-space: pre;
}
header > a {
  position: absolute;
  top: 5px;
  right: 40px;
  text-decoration: none;
  color: rgb(206, 224, 253);
  font-size: 40px;
  cursor: pointer;
  transition: ease-in 0.1s;
}

header > a:hover {
  color: white;
}
@media only screen and (max-width: 1000px) {
  header {
    margin: 0;
    padding: 20px;
  }
  .info-container {
    justify-content: space-evenly;
    width: 100%;
  }
  .popup-content {
    padding: 0 20px;
  }
  header > a {
    top: -10px;
    right: 10px;
  }
  header > h1 {
    margin: 0 40px 10px 0;
  }
}
