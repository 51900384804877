@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind commonComponents;
body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

.md\:text-left {
  text-align: left !important;
}

.ms-Icon--StreamLogo:before {
  content: '';
}