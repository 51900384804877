* {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}


.ms-Toggle-background{
  background-color: transparent;
  border: 1px solid #0369a1;
}
.ms-Toggle-background[aria-checked="true"] {
  background-color: #0369a1;
}
.ms-Toggle-background span{
  background-color: #0369a1;
}
.ms-Toggle-background[aria-checked="true"] span{
  background-color: #fff;
}