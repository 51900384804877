.container {
  display: flex;
}
.detail-list {
  width: 50vw;
  overflow: hidden;
  cursor: pointer;
}
.service-name {
  font-size: larger;
}
i {
  cursor: pointer;
}

.first-layer-header {
  background-color: rgb(70, 71, 117);
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.first-layer-header > h1 {
  color: white;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 28px;
}
