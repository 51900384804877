.nav {
  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: #f3f2f1;
  color: #484644;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 400px;
  height: 60px;
}
.nav p {
  margin: 0;
  margin-left: 20px;
  cursor: pointer;
}
.nav h2 {
  margin: 16px 0;
  color: rgb(37, 36, 35);
  font-size: 18px;
  font-weight: 700;
}

.nav > .left {
  display: flex;
  align-items: center;
}
.nav > .right {
  display: flex;
  align-items: center;
}

.nav > .left > img {
  height: 30px;
  border-radius: 4px;
  margin: 0px 20px 0 25px;
}

.nav > .right > img {
  height: 20px;
  margin-right: 20px;
}

.aktiv {
  color: rgb(98, 100, 167);
  font-weight: 600px;
}
