.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Segoe UI", SegoeUI, "Segoe WP", Tahoma, Arial, sans-serif;
  color: rgb(50, 50, 50);
}
.seperator {
  width: 100%;
  margin-bottom: 30px;
}
.seperator > div {
  margin-right: calc(67% - 12px);
}
.inputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 70%;
  padding: 30px 0 20px 0;
}
.dropdown {
  width: 300px;
}
.searchbox {
  width: 200px;
}
.keywordContainer {
  margin-bottom: -29px;
}
.keywordContainer > .errorMessage {
  color: rgb(164, 38, 44);
  font-size: 12px;
  height: 19px;
  font-weight: 400;
}
.keywordContainer > div {
  display: flex;
}

.keywordContainer > p {
  font-size: 14px;
  font-weight: 600;
  margin: 5px;
  margin-left: 0;
  color: rgb(50, 49, 48);
}

.IconButton {
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
  margin: 0 0 0 5px;
}
.IconButton:hover {
  background-color: rgb(0, 90, 158);
  color: white;
}

/* -------------------------------------------------------------- */

.items {
  width: 70%;
}

.itemscontainer {
  margin-bottom: 30px;
  display: flex;
  padding: 30px 20px 20px 30px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.date {
  margin-right: min(80px, 10vw);
  white-space: nowrap;
  font-size: 17px;
  font-weight: 600;
}
.itemscontainer h3 {
  color: rgb(50, 50, 50);
  font-size: 17px;
  font-weight: 600;
  margin-top: 0;
}
.itemscontainer p {
  font-size: 14px;
}
.itemscontainer li {
  font-size: 14px;
}
.footer {
  background-color: white;
  font-size: 80%;
  padding: 0 0 20px 20px;
}

@media only screen and (max-width: 1000px) {
  .keywordContainer {
    width: 100%;
    margin: 0;
  }
  .searchbox {
    width: 100%;
  }
  .dropdown {
    width: 100%;
  }

  .inputs {
    flex-direction: column;
    width: 95%;
    align-items: center;
    padding: 20px;
  }
  .seperator > div {
    margin-right: 0;
  }
  .items {
    width: 95%;
  }
  .itemscontainer {
    flex-direction: column;
    padding: 10px 15px;
  }
  .itemscontainer .date {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 400;
  }
  .itemscontainer h3 {
    text-align: center;
    font-weight: 700;
  }
}
