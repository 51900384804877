* {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.header {
  background-color: rgb(70, 71, 117);
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.header > h1 {
  color: white;
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
}
.input-container {
  display: flex;
  padding-left: 20px;
  margin: 40px 0 30px 0px;
  justify-content: space-between;
}

.input-container > div {
  display: flex;
}
.input-container > span {
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px 7px 0;
}
.list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
}
.button-container {
  display: flex;
  margin-right: 20px;
  align-items: flex-end;
}

a {
  color: rgb(96, 94, 92);
  cursor: pointer;
  text-decoration: underline;
}
.dropdown {
  width: 300px;
  margin-left: 11px;
}

.icon-label {
  margin-right: 10px;
}

.normal-small {
  font-size: 14px;
  font-weight: normal;
}
.keyword-container {
  padding-left: 11px;
}
.keyword-container > p {
  font-size: 14px;
  font-weight: 600;
  margin: 5px;
  margin-left: 0;
  color: rgb(50, 49, 48);
}
.toggle {
  margin-left: 11px;
}
.archive-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 30px 10px 30px;
}

.archive-container > div > span {
  cursor: pointer;
  margin-left: 20px;
}

.archive-container-active {
  font-weight: bold;
  border-bottom: rgb(0, 120, 212) solid 3px;
}

@media only screen and (max-width: 1100px) {
  .input-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .dropdown-container {
    flex-direction: column;
    margin-left: 0;
  }
  .keyword-container {
    padding-left: 0;
    width: 100%;
  }
  .button-container {
    margin-right: 0;
  }
  .input-container {
    padding-left: 0;
  }
  .dropdown {
    width: 90vw;
    margin-left: 0;
  }
  .searchBar-container {
    width: 90vw;
  }
  .download-button {
    display: none;
  }
}
