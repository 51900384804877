.sharepopup-container > .textfields {
  margin-top: 20px;
}

.sharepopup-container > .button {
  margin-top: 40px 0 40px 0px;
}
.sharepopup-container > .button-container {
  margin: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.sharepopup-container > .button-container > .button {
  width: 150px;
}
.sharepopup-container > h1 {
  margin-top: 0;
  border-bottom: 3px solid rgb(0, 120, 212);
}
.errorMessage {
  color: rgb(136, 4, 4);
  font-weight: bold;
  font-size: 10px;
  text-align: center;
}
.textfields i {
  color: #7a7fea;
  font-size: 17px;
  margin-right: 5px;
}
