.IconButton,
.IconButtonCloud {
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
  margin: 0 0 0 5px;
}
.IconButton:hover,
.IconButtonCloud:hover {
  background-color: rgb(0, 90, 158);
  color: white;
}
.spinnercontainer {
  margin: 5px;
}
