body {
  margin: 0;
  background-color: white;
}
.closebutton {
  margin: 20px 45% 20px 45%;
}
.highlight-FilterKeyword {
  background-color: gold;
}
.demoNavLink{
  text-decoration: none;
  margin-left: 20px;
}
.demoNavLink:hover{
  color: rgb(98,100,167);
}
.demoNavLink.active{
  color: rgb(98,100,167);
  font-weight: 600;
}
