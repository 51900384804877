.popup-container > header > a {
  position: absolute;
  top: 5px;
  right: 30px;
  text-decoration: none;
  color: rgb(206, 224, 253);
  font-size: 40px;
  cursor: pointer;
  transition: ease-in 0.1s;
}

.popup-container > header {
  background-color: rgb(70, 71, 117);
  position: sticky;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.popup-container > header > h1 {
  color: white;
  margin: 0;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  line-height: 40px;
}
.popup-container > header > span {
  color: white;
}
.popup-container > hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid rgb(58, 92, 148);
  margin: 20px 0 30px 0;
  padding: 0;
}

@media only screen and (max-width: 1000px) {
  .popup-container > .messages {
    margin-left: 20px;
  }
  .popup-container > header {
    padding: 20px 40px 20px 20px;
  }
}
