.peoplePicker > span {
  color: #323130;
  font-size: 14px;
}
.peoplePicker {

}
.peoplePicker i {
  font-size: 17px;
  margin-right: 5px;
  color: rgb(233, 151, 0);
}
